<template>
  <LSection>
    <template #body>
      <div class="analysis">
        <div class="heading1">
          Analysis
          <hr class="gradientLine" />
        </div>
        <LSection :use-margin="false">
          <template #body>
            <div class="text-justify">
              Within the EQRC-Analysis, we are spearheading research in the
              quantum technology industry. QT is an ever-changing landscape and
              the job market reflects that. What are the skills needed for the
              workforce of the present and the future? And how are nations of
              the world building their quantum industries?
              <br />
              <br />
              Below is a sample of the analysis we are conducting. If you are
              interested to find out more,
              <a class="gradientText hoverUnderline" href="/contact"
                >contact us.</a
              >
            </div>
          </template>
        </LSection>
        <CardAnalysis class="mb-12"></CardAnalysis>
        <LSection class="mb-12" :use-margin="false">
          <template #body>
            <div>
              The EQRC works with
              <a class="gradientText hoverUnderline" href="/#/about/us"
                >virtual scholars</a
              >
              on many of the above projects and others. If you are interested in
              working with us, please contact
              <a
                class="gradientText hoverUnderline"
                href="mailto:sherson@mgmt.au.dk"
                >sherson@mgmt.au.dk</a
              >.
            </div>
          </template>
        </LSection>
      </div>
    </template>
  </LSection>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import CardAnalysis from "@/views/analysis/CardAnalysis.vue";
// import JobMap from "@/views/analysis/JobMap.vue";
import { onMounted } from "vue";

export default {
  components: {
    // JobMap,
    CardAnalysis,
    LSection,
  },
  setup() {
    const setHeadingColor = () => {
      const heading = document.getElementById("analysisHeader");
      // if (window.scrollY < 1340) {

      if (heading !== null && heading?.classList) {
        heading.classList.add("text-white");
        heading.classList.remove("text-black");
      }
      // } else {
      //   heading.classList.add('text-black')
      //   heading.classList.remove('text-white')
      // }
    };
    onMounted(() => {
      window.addEventListener("scroll", setHeadingColor);
    });
    return {};
  },
};
</script>

<style scoped></style>
