<template>
  <div
    class="cardAnalysis grid gap-8 mt-8 h8:grid-cols-3 grid-cols-1 text-center items-center"
  >
    <div
      class="flex flex-col items-center gap-2 k1:gap-10 bg-white p-3 rounded-2xl shadow-lg h-full justify-center"
      v-for="section in sections"
      :key="section.title"
    >
      <div class="icon flex items-center justify-center w-32 flex-shrink-0">
        <img :src="section.img" class="object-contain" />
      </div>
      <div class="text">
        <div class="title heading3 font-bold mb-2 !text-center">
          {{ section.title }}
        </div>
        <div class="">
          {{ section.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sections } from "@/data/analyseSectionData";

export default {
  name: "CardAnalysis",
  components: {},
  setup() {
    return { sections };
  },
};
</script>

<style scoped></style>
